import React, {useEffect, useState} from 'react';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import FormObmen from "../FormObmen";

import styled, {css} from 'styled-components'
import {NavLink} from "react-router-dom";
import UnsafeComponent from "../UnsafeComponent";
import {logDOM} from "@testing-library/react";
import Loader from "../Loader";
const VariantOne = ({nizText, footerText, fMenu,menu,text,van,to,typ,timer,logo}) => {

	const [loading, setLoading] = useState(true)
	const [firstThreeElements, setFirstThreeElements] = useState()
	const [ToThreeElements, setToThreeElements] = useState()

	useEffect(() => {

		if(menu){
			setFirstThreeElements(menu.slice(0, 3))
			setToThreeElements(menu.slice(3))
			setLoading(false)
		}
	}, [menu]);




if(loading){
	return (
		<Loader/>
	)
}

	return (
		<div className="wrapper">
			<div className="content">
				<div className="topHeadr d-none d-lg-block"></div>
				<div className="container-fluid position-relative nav-bar p-0">
					<div className="container-lg position-relative p-0 px-lg-3" style={{zIndex: 9}}>

						<Navbar collapseOnSelect expand="lg" className="bg-white navbar-light shadow p-lg-0">
							<Navbar.Brand  className="mx-5 d-lg-none">
								<NavLink to="/" className="text-decoration-none">
									<h1 className="m-0 text-primary">
										<i className="bi bi-arrow-left-right"></i> {logo}
									</h1>
								</NavLink>
							</Navbar.Brand>
							<Navbar.Toggle aria-controls="responsive-navbar-nav" className="me-3"/>
							<Navbar.Collapse id="responsive-navbar-nav">
								<Nav className="navbar-nav ml-auto py-0 text-center">
									{firstThreeElements && firstThreeElements.map(dan =>
										<NavLink key={dan.id} to={dan.http} className="nav-item nav-link active">{dan.name}</NavLink>
									)}

								</Nav>

								<Navbar.Brand  className="mx-5 d-none d-lg-block">
									<NavLink to="/" className="text-decoration-none">
									<h2 className="m-0  text-primary">
										<i className="bi bi-arrow-left-right"></i> {logo}
									</h2>
									</NavLink>
								</Navbar.Brand>

								<Nav className="navbar-nav mr-auto py-0 text-center">
									{ToThreeElements && ToThreeElements.map(dan =>
										<NavLink key={dan.id} to={dan.http} className="nav-item nav-link active">{dan.name}</NavLink>
									)}
								</Nav>
							</Navbar.Collapse>


						</Navbar>

					</div>
				</div>

				<div className="d-flex justify-content-center">
					<div className=" text-center logo my-5">

					</div>
				</div>
				<Container className="container-lg container-fluid">
					{typ === 'obmen' &&
						<FormObmen
							btnC="oneBtn"
							van={van}
							to={to}
							timer={timer}
						/>
					}
				</Container>

				<div className="container py-5"><UnsafeComponent html={text}/></div>


			</div>

			<div className="container-fluid footer py-5">
				<hr/>
				<div className="container text-center py-5">
					<div className="row">
						<div className="col-12 mb-4">
							<NavLink to="/" className="navbar-brand m-0">
								<h1 className="m-0 text-primary">
									<i className="bi bi-arrow-left-right"></i> {logo}
								</h1>
							</NavLink>
						</div>
						<div className="col-12 mb-4">
							<a className="btn btn-outline-success btn-social me-2" href="#"><i className="bi bi-twitter"></i></a>
							<a className="btn btn-outline-success btn-social me-2" href="#"><i className="bi bi-facebook"></i></a>
							<a className="btn btn-outline-success btn-social me-2" href="#"><i className="bi bi-linkedin"></i></a>
							<a className="btn btn-outline-success btn-social" href="#"><i className="bi bi-instagram"></i></a>
						</div>


						<div className="col-12 mt-2 mb-4">
							<Container fluid="md" className="text-center text-md-start">
								<Row className="py-2">
									<Col md={3} className="my-3">
										<h3><i className="bi bi-arrow-left-right colorN"></i> {logo}</h3>
										<div className="tFo"><UnsafeComponent html={footerText}/></div>

									</Col>
									<Col md={9} className="my-3">
										<div className="d-block d-md-flex justify-content-around">
											{fMenu && fMenu.map((m,index)=>
												<div className="flex-grow-0" key={index}>
													<h4>{m[0].cat}</h4>
													<ul className="menuFooter "style={{
														listStyleType: 'none',
														padding:3
													}}>
														{m.map((n,index) =>
															<li key={index}>
																<NavLink to={n.http} className="text-black text-decoration-none">
																{n.name}
															</NavLink>
															</li>
														)}
													</ul>

												</div>
											)}
										</div>
									</Col>

								</Row>
								<hr/>

							</Container>
						</div>
						<div className="col-12">
							<div className="m-0">
								<UnsafeComponent html={nizText}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VariantOne;