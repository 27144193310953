import {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {getMenu, getSettings} from "./http/danie";
import Loader from "./component/Loader";
import Main from "./component/Main";
import NoPages from "./component/NoPages";

function App() {
	const [variant, setVariant] = useState('')
	const [menu, setMenu] = useState(null)
	const [menuFooter, setMenuFooter] = useState(null)
	const [fMenu, setFMenu] = useState(null)

	const [settings, setSettings] = useState(null)
	const [loading, setLoading] = useState(true)
	const getMenuFunc = () => {
		getMenu().then(data=>{
			if(data.status){
				setMenu(data.menu)
				setMenuFooter(data.footermenu)
				setFMenu(data.fmenu)
			}
		})
	}

	const getSettingsFunc = () => {
		getSettings().then(data=>{
			if(data.status){
				setSettings(data.setting)
				setVariant(data.setting.design)
			}
		}).finally(
			setTimeout(()=>{
				setLoading(false)
			},200)

		)
	}
	useEffect(() => {
		getMenuFunc()
		getSettingsFunc()
	}, []);
	useEffect(() => {

		variant === '1' && import('./one.css')

		variant === '2' && import('./to.css')

		variant === '3' && import('./three.css')
	}, [variant]);


	if(loading){
		return (<Loader/>)
	}

  return (
		<>
			<BrowserRouter>
				<Routes>
					{menu
						?
						menu.map((dan,index) =>
							<Route key={index} path={dan.http} element={<Main
								variant = {variant}
								path={dan.http}
								text={dan.text}
								typ={dan.typ}
								title={dan.title}
								des={dan.des}
								name={dan.name}
								menu={menu}
								van={dan.van}
								to={dan.to}
								timer={settings.timing}
								logo={settings.logo}
								slogan={settings.slogan}
								minitext={settings.minitext}
								footerText={settings.textf}
								nizText={settings.niztext}
								menuFooter={menuFooter}
								fMenu={fMenu}
							/>}/>
						)
						:
						<Route path='/' element={<Main variant = {variant}/>}/>
					}
					{menuFooter && menuFooter.map((dan,index) =>
						<Route key={index} path={dan.http} element={<Main
							variant = {variant}
							path={dan.http}
							text={dan.text}
							typ={dan.typ}
							title={dan.title}
							des={dan.des}
							name={dan.name}
							menu={menu}
							van={dan.van}
							to={dan.to}


							timer={settings.timing}
							logo={settings.logo}
							slogan={settings.slogan}
							minitext={settings.minitext}
							footerText={settings.textf}
							nizText={settings.niztext}


							menuFooter={menuFooter}
							fMenu={fMenu}

						/>}/>
					)}


					<Route path='*' element={<NoPages/>} code={404}/>



				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
