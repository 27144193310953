import {$host} from "./index";

export const getSettings = async  () => {
	const {data} = await $host.get('?getsettings=1')
	return data
}

export const getMenu = async  () => {
	const {data} = await $host.get('?getmenu=1')
	return data
}