import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "react-bootstrap";

const NoPages = () => {
	return (
		<div className="d-flex justify-content-center align-items-center vh-100">
			<div className="text-center">
				<p>Страница не найдена</p>
				<NavLink to={'/'}><Button>На главную</Button></NavLink>
			</div>
		</div>
	);
};

export default NoPages;