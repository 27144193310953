import React, {useEffect} from 'react';
import VariantOne from "./variantone/VariantOne";
import VariantTo from "./variantto/VariantTo";
import VariantThree from "./variantthree/variantThree";

const Main = ({fMenu,variant, des, title, typ, menu,text, van, to, timer, logo, slogan, minitext,nizText, footerText, menuFooter}) => {

	useEffect(() => {
		document.title = title;
		const metaDescriptionElement = document.querySelector('meta[name="description"]');
		metaDescriptionElement.setAttribute('content', des);
	}, [title]);



	return (
		<div>
			{variant === '1' && <VariantOne  fMenu={fMenu} text={text} menu={menu} van={van} to={to} typ={typ} timer={timer} logo={logo} footerText={footerText} nizText={nizText} />}
			{variant === '2' && <VariantTo fMenu={fMenu}  text={text} menu={menu} van={van} to={to} typ={typ} timer={timer} logo={logo} slogan={slogan} minitext={minitext} footerText={footerText} nizText={nizText} />}
			{variant === '3' && <VariantThree fMenu={fMenu} text={text} menu={menu} van={van} to={to} typ={typ} timer={timer} logo={logo} footerText={footerText} nizText={nizText} />}
		</div>
	);
};

export default Main;