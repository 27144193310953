import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";

const Vip2Spisok = ({btnC,valuta,func,val}) => {
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
		<a
			href=""
			className={"btn btn-light d-flex justify-content-between "}
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			<span>{children}</span>
			<span>&#x25bc;</span>
		</a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
	const CustomMenu = React.forwardRef(
		({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
			const [value, setValue] = useState('');

			return (
				<div
					ref={ref}
					style={style}
					className={className}
					aria-labelledby={labeledBy}
				>
					<Form.Control
						autoFocus
						className="mx-3 my-2 w-auto"
						placeholder="Валюта"
						onChange={(e) => setValue(e.target.value)}
						value={value}
					/>
					<ul className="list-unstyled">
						{React.Children.toArray(children).filter(
							(child) =>
								!value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
						)}
					</ul>
				</div>
			);
		},
	);

	return(
		<Dropdown >

			<Dropdown.Toggle
				as={CustomToggle}
				id="dropdown-custom-2"



			>
				{valuta.map((value, index)=> <>{value.kod === val &&
					<span key={index}>
						<img style={{
							height:'25px',
							marginRight:'20px',
						}} src={value.img}/>
						{value.title}</span>}
					</>
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu
				as={CustomMenu}
				style={{
					height:400,
					overflowY:"scroll",
				}}

			>

				{valuta.map((value, index)=>
					<Dropdown.Item eventKey={index} key={index} onClick={()=>func(value.kod)}
						style={{
							backgroundImage: `url(${value.img})`,
							backgroundRepeat:'no-repeat',

							backgroundSize: "auto 70%",
							backgroundPosition: "left 7px top 15%",

							borderBottom: '1px solid #eee',
							padding:'5px 5px 5px 40px'
						}}
					>
						{value.title}

					</Dropdown.Item>
				)}



			</Dropdown.Menu>
		</Dropdown>
	);
}
export  default Vip2Spisok

