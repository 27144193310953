import axios from "axios";


const $host = axios.create({
	//baseURL: 'https://zakaz:8890/parser/gtr'
	 baseURL: 'https://arkaweb.ru/gtr/'
})

export {
	$host
}