import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, DropdownButton, Form, Image, Row, Spinner, SplitButton, Table} from "react-bootstrap";
import {getDanie, getSites} from "../http/pars";
import {NumericFormat} from "react-number-format";
import VipSpisok from "./block/VipSpisok";
import Vip2Spisok from "./block/Vip2Spisok";

const FormObmen = ({btnC,van,to, tVariand, linkS, bgR,timer}) => {

	const [valuta, setValuta] = useState([])
	const [main, setMain] = useState(1)
	const [loading, setLoading] = useState(true)
	const [loading2, setLoading2] = useState(false)

	const [giveSumma, setGiveSumma] = useState(0);
	const [getSumma, setGetSumma] = useState(0);

	const [fromValue, setFromValue] = useState(van)
	const [toValue, setToValue] = useState(to)

	const [imgF, setImgF] = useState('')
	const [imgT, setImgT] = useState('')


	const [cityValue, setCityValue] = useState(1)

	const [sites,setSites] = useState([])
	const [city,setCity] = useState([])

	const [checkCash, setCheckCash] = useState(false);
	// const [timeO, setTimeO] = useState(timer)
	const [timeO, setTimeO] = useState(300)

	const getValuta = () => {
		getDanie().then(data => {
			setValuta(data.valuta)
			setCity(data.city)
		}).finally(()=>{
			setLoading(false);
		})
	}


	useEffect(() => {
		getValuta();
	}, []);

	useEffect(() => {
		setFromValue(van)
		setToValue(to)
	}, [van, to]);

	const getSiteFunc = () => {
		setLoading2(true);

		setTimeout(()=>{
			setSites([])

			getSites(fromValue,toValue,cityValue).then(data=>{
				if(data.status){
					setSites(data.sites)
				}
			}).finally(()=>{
				setLoading2(false);
			})
		}, 500)


	}
	useEffect(() => {
		if(fromValue && toValue ){
			setCheckCash(false)
			if (fromValue.startsWith("CASH")) {
				setCheckCash(true)
			}
			if (toValue.startsWith("CASH")) {
				setCheckCash(true)
			}
			getSiteFunc()
			const timer = setInterval(() => {
				getSiteFunc()
			}, timeO*1000);
			// очистка интервала
			return () => clearInterval(timer);
		}
	}, [fromValue,toValue, cityValue,timeO]);

	const onChange = (event) => {
		const value = event.target.value;
		setFromValue(value);
	};

	const onChange2 = (event) => {
		const value = event.target.value;
		setToValue(value);
	};

	const onChange3 = (event) => {
		const value = event.target.value;
		setCityValue(value);
	};

	const giveSFunc = (data) => {
		setMain(1)
		setGiveSumma(data)
	}
	const getSFunc = (data) => {
		setMain(2)
		setGetSumma(data)
	}

	const izMes = () => {
		setFromValue(toValue)
		setToValue(fromValue)
	}




	if(loading){
		return (
			<div className="d-flex justify-content-center align-items-center" style={{
				height:'100vh'
			}}
			>
				<Spinner animation="border" variant="success" style={{
					width: 300,
					height: 300
				}} />
			</div>
		);
	}
	return (
		<div>

			<Row className="justify-content-center">
				<Col xs={5} md={4} >
					{/*<VipSpisok btnC ={btnC} valuta ={valuta} val={fromValue}  func={setFromValue} />*/}
					<Vip2Spisok btnC ={btnC}  valuta ={valuta} val={fromValue} func={setFromValue} />
				</Col>
				<Col
					xs={2} md={1}
					className="text-center"
					style={{
						padding:0,
						margin:0,
						width:'5%'
					}}
				>

						<i onClick={izMes} className="bi bi-arrow-left-right colorThree colorN" style={{
							marginTop: '2px',
							display: 'block',
							fontSize: '1.3rem'
						}}></i>

				</Col>
				<Col xs={5} md={4}>
					{/*<VipSpisok btnC ={btnC} valuta ={valuta} val={toValue}  func={setToValue} />*/}
					<Vip2Spisok  btnC ={btnC} valuta ={valuta} val={toValue}  func={setToValue} />


				</Col>
			</Row>
			<Row className="justify-content-center my-4" >
				<Col md={3} xs={4}>
					<Form.Group className="mb-3" controlId="formOtdat">
						<Form.Label>Отдаете</Form.Label>
						<Form.Control
							type="number"
							placeholder="Отадете"
							onChange={(e) =>  {
								giveSFunc(e.target.value)
								setGetSumma(0)
							}}
							value={giveSumma}
						/>
					</Form.Group>
				</Col>
				<Col md={3} xs={4}>
					<Form.Group className="mb-3" controlId="formOtdat">
						<Form.Label>Получаете</Form.Label>
						<Form.Control
							type="number"
							placeholder="Получаете"
							onChange={(e) => {
								getSFunc(e.target.value)
								setGiveSumma(0)
							}}
							value={getSumma}
						/>
					</Form.Group>
				</Col>
			</Row>


			{checkCash &&
				<Row className="justify-content-md-center my-4" >
					<Col md={4} xs={6} className="text-end">
						<span style={{
							paddingTop: "7px",
							display:"block",
							color:"red"
						}}>Выберите город</span>
					</Col>
					<Col md={4} xs={6}>

						<Form.Select
							onChange={onChange3}
							value={cityValue}
						>
							<option defaultValue value='1'>Город</option>
							{city.map((value, index)=>
								<option key={index} value={value.kod}>{value.title}</option>
							)}
						</Form.Select>
					</Col>
				</Row>
			}

			{loading2
				?
				<div className="d-flex justify-content-center align-items-center vh-50" >
					<Spinner animation="border" variant="success" />
				</div>
				:<>
				<div className="my-4 ">
					{sites.length > 0 ?
					<div
						className={"table-responsive " + bgR}
					>
						<Table
							variant={tVariand}
							bordered={false}

						>
							<thead>
							<tr>
								<th>Обменик</th>
								<th>Отдаете</th>
								<th>Получаете</th>
								<th>Мин. Сумма</th>
								<th>Макс. Сумма</th>
								<th>Резерв</th>
								{checkCash &&
									<th>Город</th>
								}

								<th>Обзор</th>
							</tr>
							</thead>
							<tbody>
							{sites.map((site,index) =>
								<tr key={index}>
									<td>
										<a className={"text-decoration-none "+linkS} href={site.site} target="_blank">{site.sitet}</a>
									</td>
									<td>
										{
											main === 1
												?
												<NumericFormat thousandSeparator="," displayType="text" value={giveSumma}/>

												:
												<NumericFormat thousandSeparator="," displayType="text" value={Math.round((getSumma  * site.in / site.out) * 1000) / 1000}/>

										}

								  <span className="ms-2" style={{fontSize:'0.7rem'}}>{site.fromPerevod}</span>
									</td>
									<td>
									{
											main === 2
												?
												<NumericFormat thousandSeparator="," displayType="text" value={getSumma}/>
												:
												<NumericFormat thousandSeparator="," displayType="text" value={Math.round(( giveSumma * site.out / site.in * 1000)) / 1000}/>
										}
										 <span className="ms-2" style={{fontSize:'0.7rem'}}>{site.toPerevod}</span>
									</td>
									<td>от {site.minamount}</td>
									<td>до {site.maxamount}</td>
									<td>
										<NumericFormat thousandSeparator="," displayType="text" value= {Math.round(site.amount)}/>
										<span className="ms-2" style={{fontSize:'0.7rem'}}>{site.toPerevod}</span>
									</td>
									{checkCash &&
										<td>{site.cityPerevod}</td>
									}
									<td>
										<a className={"btn btn-sm " + btnC} rel="noreferrer" href={site.site} target="_blank">Обменять</a>
									</td>
								</tr>
							)
							}
							</tbody>
						</Table>
					</div>
						: <p className="text-center text-danger">Результатов нет</p>
					}
				</div>
			</>
			}
		</div>
	);
};

export default FormObmen;