import React from 'react';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import FormObmen from "../FormObmen";
import {NavLink} from "react-router-dom";
import UnsafeComponent from "../UnsafeComponent";

const variantThree = ({fMenu, menu,text,van,to,typ,timer, logo,  footerText, nizText}) => {
	return (
		<div className="min-vh-100 d-flex  flex-column">
			<div className="flex-grow-1">

				<Navbar expand="md" className="bgFo2n">
					<Container fluid="md">
						<Navbar.Brand className="text-white">
							<NavLink to={"/"} className="text-white text-decoration-none">
								<i className="bi bi-arrow-left-right colorN colorThree"></i> {logo}
							</NavLink>
						</Navbar.Brand>

						<Navbar.Toggle aria-controls="basic-navbar-nav"/>
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="m-auto d-flex justify-content-around ">
								{menu && menu.map(dan =>
									<NavLink key={dan.id} to={dan.http}
													 className="nav-item nav-link menuN text-white">{dan.name}</NavLink>
								)}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>

				<div className="bgFon">
					<div className="gutentor-block"><span>
					<svg xmlns="http://www.w3.org/2000/svg"
							 viewBox="0 0 1000 100" preserveAspectRatio="none"><path
						d="M0,6V0H1000V100Z" transform="translate(0 0)"></path></svg></span></div>


				</div>



				<Container fluid="lg" className="pThree">
					{typ === 'obmen' &&
						<FormObmen
							btnC="btn-base"
							bgR='bgR'
							tVariand="light"
							linkS="colorThree"
							van={van}
							to={to}
							timer={timer}
						/>
					}

				</Container>


				<div className="bg-white container-lg container-fluid my-3 p-3 rounded-3 "><UnsafeComponent html={text}/></div>


			</div>
			<footer>
				<Container fluid="md" className="text-center text-md-start">
					<Row className="py-2">
						<Col md={3} className="my-3">
							<h3><i className="bi bi-arrow-left-right colorN"></i> {logo}</h3>
							<div className="tFo"><UnsafeComponent html={footerText}/></div>

							<i className="bi bi-facebook me-2"/>
							<i className="bi bi-instagram me-2"/>
							<i className="bi bi-telegram me-2"/>
							<i className="bi bi-pinterest me-2"/>
						</Col>
						<Col md={9} className="my-3">
							<div className="d-block d-md-flex justify-content-around">
								{fMenu && fMenu.map((m,index)=>
									<div className="flex-grow-0" key={index}>
										<h4>{m[0].cat}</h4>
										<ul className="menuFooter">
											{m.map((n,index) =>
												<li key={index}><NavLink to={n.http} className="text-white text-decoration-none">
													{n.name}
												</NavLink>
												</li>
											)}
										</ul>

									</div>
								)}
							</div>
						</Col>

					</Row>
					<hr/>
					<div className="text-center py-4">
						<UnsafeComponent html={nizText}/>
					</div>
				</Container>
			</footer>
		</div>

	)
}

export default variantThree;